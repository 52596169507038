import React from "react";
// import NavbarTop from "../Navbar/NavbarTop";
import { Box, Text } from "@chakra-ui/react";
import NavbarTopFirst from "../Navbar/NavbarTopFirst";
import Header from "../Header/HeaderFirst";
import HeaderSecond from "../Header/HeaderSecond";
import CardFirst from "../HeaderCards/CardFirst";
import Footer from "../Footer/Footer";
import { useMediaQuery } from 'react-responsive'
import TestNav from "../Navbar/testNavbar";
import { Helmet } from "react-helmet";


// const navbarStyles = {
//   position: 'fixed',
//   top: '4%',
//   width: '100%',
//   zIndex: 1000,
//   backgroundColor: '#fff',
//   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
// };

function Home() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <Box marginTop='135px'>

{/* <Helmet>
        <title>UpholdLegal - Professional Legal Services</title>
        <meta name="description" content="UpholdLegal offers expert legal services including Medical Record Retrieval, Demand Letter preparation, Lien Negotiation, and more. Discover our services and learn how we can assist you." />
        <meta name="keywords" content="UpholdLegal, legal services, Medical Record Retrieval, Demand Letter, Lien Negotiation, legal consultation, professional legal services" />
        <meta property="og:title" content="UpholdLegal - Professional Legal Services" />
        <meta property="og:description" content="UpholdLegal provides a range of legal services including Medical Record Retrieval, Demand Letter preparation, and more. Learn about our offerings and how we can help you." />
        <meta property="og:url" content="https://www.upholdlegal.com" />
        <meta property="og:image" content="https://www.upholdlegal.com" />
        <link rel="canonical" href="https://www.upholdlegal.com" />
      </Helmet> */}

      <NavbarTopFirst /> 
      <Header />
      <Box w={isTabletOrMobile ? '100%' : '80%'} ml='auto' mr="auto" top={"20px"} p={2}>
        <HeaderSecond />
        <CardFirst />
      </Box>


      <Box alignContent={"center"} alignItems={"center"}>
        {/* Apply styles to the span elements */}
        <Text
          style={{
            marginTop: "30px",
            fontSize: "35px",
            fontWeight: "bold",
            color: "#008080",
            marginLeft: "10.5%"
          }}
        >
          Why Us?
        </Text>
        <p style={{
          marginTop: "10px",
          fontSize: "20px",
          top: "20%",
          bottom: '30%',
          marginLeft: "10.5%",
          marginRight: "10.5%",
          paddingBottom: '20px',
          textAlign: 'justify',
        }}>
          Our collaboration will boost quality while reducing expenses, giving your company a competitive advantage over rivals. Give us the task of managing your company procedures so you may concentrate on the expansion of your business.
        </p>

      </Box>



      <Box ml="auto" mr="auto" bg="#1a202d">
        <Footer />
      </Box>
    </Box>
  );
}

export default Home;