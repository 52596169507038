import React, { useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom"; // Import Link component
import { Box, Button, Text } from "@chakra-ui/react";
import { useMediaQuery } from 'react-responsive'
const divStyle = {
  display: "flex",
  alignItems: "center",
  // justifyContent: 'center',
  backgroundSize: "cover",
  height: "500px", 
  position: "relative", 
};

const slideImages = [
  {
    
    url: "court1.jpg",
    caption: "Slide 1",
  },

  {
    url: "court3.jpeg",
  },
];

const HeaderFirst = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const message = "Your legal needs,";
  const [message2, setMessage2] = useState("Our personalized solutions.");

  return (
    <div className="slide-container">
      <Slide>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{ ...divStyle, backgroundImage: `url(${slideImage.url})` }}
            >
              <Box style={{ position: "relative" }} ml={28} textAlign={"left"}>
                {/* Apply styles to the span elements */}
                <Text
                  style={{ fontSize: "40px", fontWeight: "bold", top: "20%" ,color:'white'}}
                >
                  {message}
                </Text>

                <Text
                  style={{ fontSize: "40px", fontWeight: "bold", top: "80%" ,color:'white'}}
                >
                  {message2}
                </Text>
                {/* Use Link component for navigation */}

                <Box mt={10}>
                  <Link to="/appointment">
                    <Button colorScheme="teal" size="sm">
                      Book An Appointment
                    </Button>
                    {/* */}
                  </Link>
                </Box>
              </Box>
            </div>
          </div>
        ))}
      </Slide> 
    </div>
  );
};

export default HeaderFirst;
