import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Textarea,
  Container,
  FormErrorMessage,
  SimpleGrid,
} from '@chakra-ui/react';

const Appointment = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    city: '',
    address: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);

  useEffect(() => {
    if (submitSuccess) {
      setSuccessMessageVisible(true);
      const timer = setTimeout(() => {
        setSuccessMessageVisible(false);
        setSubmitSuccess(false);
      }, 20000); // Hide the message after 20 seconds

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [submitSuccess]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFocus = (e) => {
    setErrors({ ...errors, [e.target.name]: '' });
  };

  // Validate that name, email, and phone are not empty and valid
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) {
      newErrors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'Phone number is invalid';
    }

    return newErrors;
  };

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      setSubmitting(true);
      try {
        // Set default values for fields if they are empty
        const requestData = {
          name: formData.name || 'n/a',
          phone: formData.phone || 'n/a',
          email: formData.email || 'n/a',
          city: formData.city || 'n/a',
          address: formData.address || 'n/a',
          comment: formData.message || 'n/a',
        };

        console.log('Sending request with data:', requestData); // Log request data

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          console.log('Response status:', response.status); // Log response status
          console.log('Form Data:', requestData);
          setSubmitSuccess(true);
          setFormData({
            name: '',
            email: '',
            phone: '',
            city: '',
            address: '',
            message: '',
          });
        } else {
          console.error('Failed to submit form:', response.statusText);
          const errorText = await response.text();
          console.error('Error response:', errorText); // Log error response
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setSubmitting(false);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 704px)' });

  return (
    <Container maxW="container.lg" p={6} borderLeft={isTabletOrMobile ? 'none' : 'none'} marginLeft={isTabletOrMobile ? '0px' : '5px'}>
      <Box>
        <Text color={'#008080'} fontWeight="bold" fontSize="xl" mb={4}>
          Appointment Details     
        </Text>
        <form onSubmit={handleSubmit}>
          <SimpleGrid columns={isTabletOrMobile ? '1' : '2'} spacing={4}>
            <FormControl id="name" isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                onFocus={handleFocus}
                disabled={submitting}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl id="phone" isInvalid={!!errors.phone}>
              <FormLabel>Phone</FormLabel>
              <Input
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                onFocus={handleFocus}
                disabled={submitting}
              />
              <FormErrorMessage>{errors.phone}</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                onFocus={handleFocus}
                disabled={submitting}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl id="city">
              <FormLabel>City</FormLabel>
              <Input
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                onChange={handleChange}
                disabled={submitting}
              />
            </FormControl>
            <FormControl id="address">
              <FormLabel>Address</FormLabel>
              <Input
                type="text"
                name="address"
                placeholder="Address"
                value={formData.address}
                onChange={handleChange}
                disabled={submitting}
              />
            </FormControl>
            <FormControl id="message">
              <FormLabel>Message</FormLabel>
              <Textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                disabled={submitting}
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" isLoading={submitting}>
              Submit
            </Button>
            {successMessageVisible && (
              <Text color="green.500" textAlign="center">
                Your Appointment Details Submitted Successfully!
                We will get back to you soon
              </Text>
            )}
          </SimpleGrid>
        </form>
      </Box>
    </Container>
  );
};

export default Appointment;
