import React from 'react';
import NavbarTopFirst from '../Navbar/NavbarTopFirst';
import Footer from '../Footer/Footer';
import { useMediaQuery } from 'react-responsive';
import { FaFileMedical, FaFileAlt, FaHandshake, FaEnvelopeOpenText } from 'react-icons/fa';
import { Helmet } from "react-helmet";

const ServicesOffered = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 704px)" });

  return (
    <div>
      {/* <Helmet>
        <title>Services offered - UpholdLegal</title>
        <meta name="description" content="Learn more about Uphold Legal, our mission, and the professional legal services we offer." />
        <meta name="keywords" content='Medical Record Retrieval,Deposition and Trial Transcript Summaries,Uphold Legal services,legal services,i want legal service, legal services,Lien Negotiation,Demand Letter,law firm, legal consultation' />
        <link rel="canonical" href="https://www.upholdlegal.com/Services offered" />
      </Helmet> */}

      <NavbarTopFirst />
      <div style={{ position: 'relative', width: '100%', height: '100px', backgroundColor: "#008080", display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1%', marginTop: '130px' }}>
        <h1 style={{ margin: '0', fontWeight: 'bold', color: 'white', fontSize: '2.5rem' }}>Our Services</h1>
      </div>
      <img
        src="./court8.jpg"
        alt="Placeholder Image"
        className="about-image"
        style={{ width: '100%', height: '400px', objectFit: 'contain' }}
      />
      <p style={{ margin: '50px', marginTop: isTabletOrMobile ? '0px' : '40px', color: '#000000', fontSize: '1rem', textAlign: 'center', textAlign: 'justify', textJustify: 'inter-word' }}>
        We ensure the best possible return on investment to accelerate the growth of our clients. We achieve synergy by including the skills and ideas of all our team members. We have a well-placed Information Security System to ensure the security of data and processes for our clients.
      </p>
      <div style={{ display: 'grid', gridTemplateColumns: isTabletOrMobile ? '1fr' : '1fr 1fr', gap: '20px', marginTop: '30px', padding: '0 10px', boxSizing: 'border-box' }}>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'white', borderRadius: '8px' }}>
          <div>
            <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
              <FaFileMedical size={35} style={{ marginRight: '10px', color: '#008080' }} />
              <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Medical Record Retrieval</span>
            </h2>
            <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: '20px', marginRight: '20px', textAlign: 'justify', textJustify: 'inter-word' }}>
              <strong>Our medical record retrieval services help expedite the lien negotiation and demand letter preparation processes.</strong><br /><br />
              Our medical records retrieval services relieve the burden of obtaining medical records, helping you to focus on preparing a compelling case. We quickly obtain sensitive records, including billing records and medical records. To ensure that every stage of our procedure complies with the strict legal guidelines established by the state of California, we start by carefully collecting the required authorizations. Using a proactive approach to speed up the retrieval process while preserving the greatest degree of accuracy, we take great satisfaction in building smooth communication channels with healthcare providers.
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'white', borderRadius: '8px' }}>
          <div>
            <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
              <FaHandshake size={35} style={{ marginRight: '10px', color: '#008080' }} />
              <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Lien Negotiation</span>
            </h2>
            <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: '20px', marginRight: '20px', textAlign: 'justify', textJustify: 'inter-word' }}>
              <strong>Our commitment is to offer unmatched lien negotiation services to get optimized results and memorable experiences.</strong><br /><br />
              We manage all Settlement Distributions through our Lien Specialists. After the matter is resolved or a verdict is rendered, the procedure begins. Our goal is to maximize our clients' financial recovery by bringing every outstanding medical bill and lien from all medical/lien providers down to the lowest possible amount. Our Lien Specialists vigorously negotiate and battle for a recovery amount that is fair for clients, regardless of the obstacles they encounter, in order to resolve healthcare liens in personal injury settlements.
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'white', borderRadius: '8px' }}>
          <div>
            <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
              <FaEnvelopeOpenText size={35} style={{ marginRight: '10px', color: '#008080' }} />
              <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Demand Letter</span>
            </h2>
            <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: '20px', marginRight: '20px', textAlign: 'justify', textJustify: 'inter-word' }}>
              <strong>Our productive demand letter service is intended to secure your clients a fair settlement without the expense or duration of going into litigation.</strong><br /><br />
              The main objective of the demand letter in personal injury litigation is to convince the insurance company to settle the case by providing important information about the accident, injuries, treatment, and costs. Insurance companies usually fight these kinds of litigation hard. For the purpose of reaching a just out-of-court settlement, a well-written demand letter provides a summary of medical treatments and expenses, together with supporting documentation. In addition, an effective demand letter service can expedite settlement prior to trial, thereby conserving expenses and time. Our service seeks to avoid the need for drawn-out litigation by securing fair settlements for customers.
            </p>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'white', borderRadius: '8px' }}>
          <div>
            <h2 style={{ display: 'flex', alignItems: 'center', fontSize: '24px' }}>
              <FaFileAlt size={35} style={{ marginRight: '10px', color: '#008080' }} />
              <span style={{ fontWeight: 'bold', color: '#000000', fontSize: '1.4rem' }}>Deposition and Trial Transcript Summaries</span>
            </h2>
            <p style={{ fontSize: '16px', lineHeight: '1.6', color: '#333333', borderTop: 'none', marginTop: '0', marginLeft: '20px', marginRight: '20px', textAlign: 'justify', textJustify: 'inter-word' }}>
              <strong>Our transcript summary services may be sent quickly, helping you to keep your lawsuit preparation on time.</strong><br /><br />
              An essential tool for attorneys to convey the key points from a deposition or trial transcripts is a transcript summary. A transcript of a deposition or trial usually consists of hundreds of pages of transcripted conversations, which makes it very difficult for an attorney to read and retain everything. Here's when summaries start to matter. Through depositions, the plaintiff and the defendant can learn more about potential witnesses and the kinds of testimony they may be able to provide during a trial. The pre-trial proceeding moves quickly. We are aware that timely delivery without sacrificing product quality is necessary. We provide a carefully selected deposition summary that is in line with your practice because we are aware of the challenges and legal problems associated with such situations.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesOffered;


// const services = [
//   {
//     title: 'Medical Record Retrieval',
//     description: 'Our medical records retrieval services relieve the burden of obtaining medical records, helping you to focus on preparing a compelling case. We quickly obtain sensitive records, including billing records and medical records.',
//     imageUrl: 'https://vitalitybss.com/wp-content/uploads/2023/02/d04699cd44cae645373502b95ca9f871.jpg',
//   },
//   {
//     title: 'Demand Letter',
//     description: `Our productive demand letter service is intended to secure your clients a fair settlement without the expense or duration of going into litigation.

//     The main objective of the demand letter in personal injury litigation is to convince the insurance company to settle the case by providing important information about the accident, injuries, treatment, and costs. Insurance companies usually fight these kinds of litigation hard.

//     For the purpose of reaching a just out-of-court settlement, a well-written demand letter provides a summary of medical treatments and expenses, together with supporting documentation.

//     In addition, an effective demand letter service can expedite settlement prior to trial, thereby conserving expenses and time. Our service seeks to avoid the need for drawn-out litigation by securing fair settlements for customers.`,
//     imageUrl: 'https://res.cloudinary.com/upwork-cloud/image/upload/c_scale,w_1000/v1709210666/catalog/1635716662208933888/kr2jbybejrohwq6g5yy1.webp',
//   },
//   {
//     title: 'Lien Negotiation',
//     description:`We manage all Settlement Distributions through our Lien Specialists. After the matter is resolved or a verdict is rendered, the procedure begins.

//     Our goal is to maximize our clients' financial recovery by bringing every outstanding medical bill and lien from all medical/lien providers down to the lowest possible amount.

//     Our Lien Specialists vigorously negotiate and battle for a recovery amount that is fair for clients, regardless of the obstacles they encounter, in order to resolve healthcare liens in personal injury settlements.`,
//     imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdds3JJOtoNjCUblll2Pxa0XJRIjL8yviR1UPGgVpYA-AuTKYADiHzeulnWbNuPEGA1Bg&usqp=CAU',
//   },
//   {
//     title: 'Deposition and Trial Transcript Summaries',
//     description: `An essential tool for attorneys to convey the key points from a deposition or trial transcripts is a transcript summary. A transcript of a deposition or trial usually consists of hundreds of pages of transcripted conversations, which makes it very difficult for an attorney to read and retain everything. Here's when summaries start to matter. Through depositions, the plaintiff and the defendant can learn more about potential witnesses and the kinds of testimony they may be able to provide during a trial.

//     The pre-trial proceeding moves quickly. We are aware that timely delivery without sacrificing product quality is necessary.We provide a carefully selected deposition summary that is in line with your practice because we are aware of the challenges and legal problems associated with such situations.`,
//     imageUrl: 'https://www.completelegaloutsourcing.com/wp-content/uploads/2023/09/Deposition-Summary-Services-Management.jpg',
//   },

// ];

// const ServicesOffered = () => {
//   const isTabletOrMobile = useMediaQuery({ query: '(max-width: 704px)' });

//   let styles;
//   if (isTabletOrMobile) {
//     styles = {
//       services: {

//         textAlign: 'center',
//         padding: '0px 0px',
//         backgroundColor: '#white',
//       },
//       title: {
//         fontSize: '36px',
//         color: '#333',
//         marginBottom: '20px',
//       },
//       servicesList: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'center',
//         gap: '30px',
//       },
//       serviceItem: {
//         backgroundColor: '#ffffff',
//         border: '1px solid #ddd',
//         borderRadius: '8px',
//         padding: '25px',
//         margin: '20px',
//         width: '280px',
//         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//         textAlign: 'center',
//       },
//       serviceImageWrapper: {
//         height: '150px', // Set a fixed height for the image wrapper
//         overflow: 'hidden', // Ensure images don't overflow their containers
//         borderRadius: '8px 8px 0 0', // Apply border radius to match the service item
//       },
//       serviceImage: {
//         width: '120%', // Allow images to fill their containers horizontally
//         height: '35%', // Ensure images maintain aspect ratio within the fixed height
//         objectFit: 'cover', // Cover option ensures images maintain aspect ratio while filling the container
//       },
//       serviceTitle: {
//         fontSize: '24px',
//         color: '#333',
//         margin: '20px 0 10px 0',
//       },
//       serviceDescription: {
//         fontSize: '16px',
//         color: '#666',
//       },
//     };
//   } else {
//     styles = {
//       services: {
//         textAlign: 'center',
//         padding: '10px 10px',
//         backgroundColor: '#white',
//         borderLeft: '10px solid transparent',
//         borderRight: '10px solid transparent',
//       },
//       title: {
//         fontSize: '36px',
//         color: '#333',
//         marginBottom: '20px',
//       },
//       servicesList: {
//         display: 'flex',
//         flexWrap: 'wrap',
//         justifyContent: 'center',
//         gap: '20px',
//       },
//       serviceItem: {
//         backgroundColor: '#ffffff',
//         border: '1px solid #ddd',
//         borderRadius: '8px',
//         padding: '25px',
//         margin: '2px',
//         width: '280px',
//         boxShadow: '0 4px 4px rgba(0, 0, 0, 0.1)',
//         textAlign: 'center',
//       },
//       serviceImageWrapper: {
//         height: '150px', // Set a fixed height for the image wrapper
//         overflow: 'hidden', // Ensure images don't overflow their containers
//         borderRadius: '8px 8px 0 0', // Apply border radius to match the service item
//       },
//       serviceImage: {
//         width: '120%', // Allow images to fill their containers horizontally
//         height: '35%', // Ensure images maintain aspect ratio within the fixed height
//         objectFit: 'cover', // Cover option ensures images maintain aspect ratio while filling the container
//       },
//       serviceTitle: {

//         fontSize: '24px',
//         color: '#333',
//         margin: '30px 0 10px 0',
//       },
//       serviceDescription: {
//         fontSize: '16px',
//         color: '#666',
//       },
//     };
//   }

//   return (
//     <div>
//       <NavbarTopFirst/>

//         {/* <h1 style={styles.title}>Our Services</h1> */}
//         <div style={{ position: 'relative', width: '100%', height: '100px', backgroundColor: "#008080", display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1%',marginTop:'130px' }}>
//         <h1 style={{ margin: '0', fontWeight: 'bold', color: 'white' }}>Our Services</h1>
//       </div>
//       <div style={styles.services}>
//         <div style={styles.servicesList}>
//           {services.map((service, index) => (
//             <div key={index} style={styles.serviceItem}>
//               <img src={service.imageUrl} alt={service.title} style={styles.serviceImage} />
//               <h2 style={styles.serviceTitle}>{service.title}</h2>
//               <p style={styles.serviceDescription}>{service.description}</p>
//             </div>
//           ))}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default ServicesOffered;
