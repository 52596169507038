// import logo from './logo.svg';
// import './App.css';
import NavbarTop from '../src/Components/Navbar/NavbarTop';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Home from './Components/Home/Home';


import About from './Components/Pages/About';
import ServicesOffered from './Components/Pages/ServicesOffered';
import Appointment from './Components/Pages/Contact';
import Contact from './Components/Pages/Contact';
import TermsAndConditions from './Components/Pages/TermsAndConditions'
import PrivacyPolicy from './Components/Pages/PrivacyPolicy'
import NavbarTopFirst from './Components/Navbar/NavbarTopFirst';
import { Helmet } from "react-helmet";




function App() {
  return (

    <div className="App">
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Uphold Legal - Legal Services and Medical Record Retrieval</title>
        <meta name="description" content="UpholdLegal provides professional services like Medical Record Retrieval, Demand Letter preparation, Lien Negotiation, Deposition, and Trial Transcript Summaries." />
        <meta name="keywords" content="UpholdLegal services, legal services, Medical Record Retrieval, Demand Letter, Lien Negotiation, Deposition, Trial Transcript Summaries" />
        <link rel="canonical" href="https://www.upholdlegal.com" />
      </Helmet> */}

      <NavbarTop />
      {/* <div style={contentStyles}> */}
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/Services offered" element={<ServicesOffered />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Appointment" element={<Contact />} />
            <Route path="/Terms And Condition" element={<TermsAndConditions />} />
            <Route path="/Privacy Policy" element={<PrivacyPolicy />} />

          </Routes>
        </BrowserRouter>
      </ChakraProvider>
      {/* </div>  */}
    </div>
  );
}

export default App;


// import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { ChakraProvider } from '@chakra-ui/react';
// import NavbarTop from './Components/Navbar/NavbarTop'; // Corrected path
// import Home from './Components/Home/Home'; // Corrected path
// import About from './Components/Pages/About'; // Corrected path
// import ServicesOffered from './Components/Pages/ServicesOffered'; // Corrected path
// import Appointment from './Components/Pages/Appointment'; // Ensure this matches the component name and path
// import Contact from './Components/Pages/Contact'; // Ensure this matches the component name and path

// function App() {
//   return (
//     <div className="App">
//       <NavbarTop />
//       <ChakraProvider>
//         <BrowserRouter>
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/about" element={<About />} />
//             <Route path="/services-offered" element={<ServicesOffered />} />
//             <Route path="/contact" element={<Contact />} />
//             <Route path="/appointment" element={<Appointment />} />
//           </Routes>
//         </BrowserRouter>
//       </ChakraProvider>
//     </div>
//   );
// }

// export default App;
