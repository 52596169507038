// import React, { useState } from "react";
import { Box, Text, SimpleGrid, Center } from "@chakra-ui/react";
import { FaWhatsapp } from "react-icons/fa";
import { TbMessageChatbot, TbApi } from "react-icons/tb";
import { AiOutlineSolution } from "react-icons/ai";
import { MdSystemSecurityUpdateGood } from "react-icons/md";

import { FaFileMedical } from 'react-icons/fa';
import { FaFileAlt } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { FaEnvelopeOpenText } from 'react-icons/fa';




import {
  Card,
  CardBody,
  // CardFooter,
  // Image,
  // Stack,
  // Heading,
  // Divider,
  // ButtonGroup,
} from "@chakra-ui/react";


const CardFirst = () => {
  const header = "Services Provided";
  return (

    <Box alignContent={"center"} alignItems={"center"}>
      {/* Apply styles to the span elements */}
      <Text
        style={{
          marginTop: "70px",
          fontSize: "35px",
          fontWeight: "bold",
          top: "30%",
          color:"#008080"
        }}
      >
        {header}
      </Text>
      {/* <Flex> */}
      <SimpleGrid spacing={2} templateColumns='repeat(auto-fill, minmax(275px, 1fr))'>
      <Card bg="#1a202d" borderRadius={'0px'} color="white" align={'center'} p={2}>
  <CardBody mt={2} display="flex" flexDirection="column" alignItems="center">
    <div style={{ paddingBottom: '29px' }}>
      <FaFileMedical size={100} />
    </div>
    <Text textAlign="center">
      Medical Record Retrieval
    </Text>
  </CardBody>
</Card>

<Card bg="#1a202d" borderRadius={'0px'} color="white" align={'center'} p={2}>
  <CardBody mt={5} display="flex" flexDirection="column" alignItems="center">
    <div style={{ paddingBottom: '20px' }}>
      <FaEnvelopeOpenText size={100} />
    </div>
    <Text textAlign="center">
      Demand Letter
    </Text>
  </CardBody>
</Card>

<Card bg="#1a202d" borderRadius={'0px'} color="white" align={'center'} p={2}>
  <CardBody mt={5} display="flex" flexDirection="column" alignItems="center">
    <div style={{ paddingBottom: '20px' }}>
      <FaHandshake size={100} />
    </div>
    <Text textAlign="center">
      Lien Negotiation
    </Text>
  </CardBody>
</Card>

        <Card bg="#1a202d" borderRadius={'0px'} color="white" align={'center'} p={2}>
          <CardBody mt={5} display="flex" flexDirection="column" alignItems="center">
            <div style={{ paddingBottom: '20px' }}>
              <FaFileAlt size={100} />
            </div>
            <Text textAlign="center">
              Deposition and Trial Transcript Summaries
            </Text>
          </CardBody>
        </Card>


      </SimpleGrid>
    </Box>


  );
};
export default CardFirst;
