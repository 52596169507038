import React from "react";
import { Box, Flex, Text, Spacer } from "@chakra-ui/react";


import { Link } from "react-router-dom"; // Importing Link component from React Router for navigation
import {
  Card,
  CardBody,
  Image,
} from "@chakra-ui/react";
// import { MdPersonalInjury } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { MdSms } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { SocialIcon } from "react-social-icons"; // Importing SocialIcon component
// import { MdEmail, MdPhone } from 'react-icons/md'; // Importing MdEmail and MdPhone icons
import { MdArrowForwardIos } from "react-icons/md";
import { useMediaQuery } from "react-responsive";
function Footer() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <Box bg="#1a202d" color="white" w="100%">
      <Box
        display={isTabletOrMobile ? "block" : "flex"}
        justifyContent={"space-around"}
      >
        <Card
          w="xs"
          bg="#1a202d"
          borderRadius={"0px"}
          color="white"
          align={"center"}
          p={6}
        >
          <a
            href="tel:9430085302"
            style={{ color: "white", textDecoration: "none" }}
          >
            <CardBody mt={2}>
              <Flex>
                <IoCall size={70} />
                <Text ml={1}>
                  CALL US NOW !
                  <br />
                  9430085302
                </Text>
              </Flex>
            </CardBody>
          </a>
        </Card>
        <Card
          bg="#1a202d"
          borderRadius={"0px"}
          color="white"
          align={"center"}
          p={6}
        >
          <a
            href="mailto:support@upholdlegal.com"
            style={{ color: "white", textDecoration: "none" }}
          >
            <CardBody mt={2}>
              <Flex>
                <MdSms size={70} />
                <Text ml={2}>
                  SEND US MESSAGE !
                  <br />
                  support@upholdlegal.com
                </Text>
              </Flex>
            </CardBody>
          </a>
        </Card>
        <Card
          bg="#1a202d"
          borderRadius={"0px"}
          color="white"
          align={"center"}
          p={6}
          pr={6}
        >
          <a
            href="https://wa.me/+919430085302"
            style={{ color: "white", textDecoration: "none" }}
          >
            <CardBody mt={2}>
              <Flex>
                <FaWhatsapp size={70} />
                <Text ml={1}>
                  WHATSAPP US!
                  <br />
                  9430085302
                </Text>
              </Flex>
            </CardBody>
          </a>
        </Card>
      </Box>
      <Box border={" 1px #2d3444 solid"}></Box>
      <Box border={" 1px #2d3444 solid"}></Box>
      {/* Footer Text */}
      <Box
        mt={7}
        fontWeight={600}
        mr={"auto"}
        ml={"auto"}
        color={"#959696"}
        p={4}
        pb={3}
      >
        <Flex flexWrap={"wrap"} justifyContent={"center"}>
          <a
            href="/"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            Home
          </a>
          <Spacer />
          <Text>
            <Link to="/Privacy Policy">Privacy Policy</Link>
          </Text>
          <Spacer />
          <Text>
            <Link to="/Terms And Condition">Terms of Service</Link>
          </Text>
        </Flex>
      </Box>
      <Text color={"#757d8b"} pb={7} textAlign={"center"}>
        Uphold Legal © All Rights Reserved
      </Text>
    </Box>
  );
}

export default Footer;
