import React from "react";
import NavbarTop from "./NavbarTop";

import { Text, Flex, Box, Image } from "@chakra-ui/react"; // Importing Chakra UI components


import { useMediaQuery } from "react-responsive"; // Importing useMediaQuery hook from react-responsive
import { Link } from "react-router-dom"; // Importing Link component from React Router for navigation

// Importing image (assuming it's a relative path)
const img = "./court.png";

// position: fixed;
// top: 0;
// width: 100%;
// background-color: #333;
// padding: 10px 0;
// text-align: center;

// NavbarTopFirst component definition
function NavbarTopFirst() {
  // Checking if device is tablet or mobile
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 604px)" });

  console.log(isTabletOrMobile, 'isTabletOrMobile-----')
  return (

    <>
      {isTabletOrMobile === false ? (
        <Box fontSize={"14px"} backgroundColor="#FFFFFF" borderBottom="3px solid black"width="100%" top="8" left="0" right={'0px'} zIndex="1000" position='fixed
        '>
          <Flex
            justifyContent="space-between"

            t={10}
            maxWidth={"80%"}
            mx="auto"
            alignItems={"center"} 
          >
            {/* Logo */}
            <Flex alignItems="center">
              <Image
                boxSize={{ base: "50px", sm: "100px", md: "100px" }}
                objectFit="cover"
                src={img}
                alt="tech logo"
              />
            </Flex>
            <Flex alignItems={"center"}></Flex>
            {/* Flex container for the right side content */}
            <Flex alignItems={"center"} justifyContent={"space-between"} w="30%">
              {/* Links */}
              <Text p={3}>
                <Link
                  to="/about"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  About
                </Link>
              </Text>
              <Text p={3}>
                <Link
                  to="/Services offered"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Paralegal Services
                </Link>
              </Text>
              <Text p={3}>
                <Link
                  to="/contact"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Contact
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Box>

      ) : (
        <Box
          fontSize="12px"
          backgroundColor="#FFFFFF"
          width="100%"
          top="70"
          zIndex="100"
          position="fixed"
          borderBottom="2px solid black"
        >
          <Flex
            justifyContent="space-between"
            maxWidth="100%"
            px="20px"  // Adjust padding as needed
            alignItems="center"
          >
            {/* Logo */}
            <Flex alignItems="center">
              <Image
                boxSize={{ base: "50px", sm: "100px", md: "100px" }}
                objectFit="cover"
                src={img}
                alt="upholdlegal logo"
              />
            </Flex>

            {/* Flex container for the right side content */}
            <Flex alignItems="center" justifyContent="space-between" width="70%">
              {/* Links */}
              <Text p={4}>
                <Link to="/about" style={{ color: "#000000", textDecoration: "none" }}>
                  About
                </Link>
              </Text>
              <Text p={4}>
                <Link
                  to="/Services offered"
                  style={{ color: "#000000", textDecoration: "none" }}
                >
                  Paralegal Services
                </Link>
              </Text>
              <Text p={4}>
                <Link to="/contact" style={{ color: "#000000", textDecoration: "none" }}>
                  Contact
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Box>


      )}
    </>


  );
}

export default NavbarTopFirst;