import React from 'react';
import { Flex, Box, Icon } from '@chakra-ui/react';
import { SocialIcon } from 'react-social-icons';
import { MdEmail, MdPhone } from 'react-icons/md';
import { TfiWorld } from "react-icons/tfi";
import { useMediaQuery } from 'react-responsive';

function NavbarTop() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 824px)' });

  const navbarStyles = {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1000,
    backgroundColor: '#000000',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    fontSize: '15px',
    maxHeight: '500px',
    color: 'white',
    
  };

  return (
    <Box style={navbarStyles}>
      <Flex justifyContent="space-between" flexDirection={isTabletOrMobile ? 'column' : 'row'} maxWidth="80%" mx="auto" alignItems="center">
        <Flex alignItems="center">
          <TfiWorld color="red" style={{ width: '14px', height: '14px' }} />
          <span style={{ marginLeft: '10px' }}>24/7 Support Services</span>
        </Flex>
        <Flex flexDirection={isTabletOrMobile ? 'column' : 'row'} justifyContent="center" alignItems="center">
          <Flex alignItems="center" mt={1}>
            <Icon as={MdEmail} style={{ height: 20, width: 20, marginRight: '5px' }} />
            <span style={{ marginRight: isTabletOrMobile? '5px':'15px', fontSize: '14px' }}>support@upholdlegal.com</span>
            {/* <Icon as={MdPhone} style={{ height: 20, width: 20, marginRight: '5px' }} />
            <span style={{ marginRight: '5px', fontSize: '14px' }}>9430085302</span> */}
          </Flex>
          <Flex mt={1} mb={1}>
            <SocialIcon url="https://www.linkedin.com/company/upholdlegal" style={{ height: 30, width: 30, marginRight: '5px' }} />
            <SocialIcon url="https://facebook.com/" style={{ height: 30, width: 30, marginRight: '5px' }} />
            <SocialIcon url="https://plus.google.com/support@upholdlegal.com" style={{ height: 30, width: 30, marginRight: '5px' }} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default NavbarTop;
